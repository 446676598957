@import url(https://fonts.googleapis.com/css?family=Fira+Sans);

:root {
  --color-red: #EC1F27;
  --color-white: white;
  --color-black: #222;
}

body {
  font-family: 'Fira Sans', sans-serif;
}

.Page {
  width: 100vw;
  height: 100vh;
  overflow: hidden auto;
  display: flex;
  flex-direction: column;
}

.Header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 12px;
  background-color: black;
  box-shadow: 5px 5px 15px 5px rgba(0,0,0, 0.05);
  font-size: 22px;
  color: white;

  & .img-holder {
    display: flex;
    & img {
      width: 96px;
      height: 96px;
      border-radius: 48px;
    }

    & span {
      align-self: center;
      text-transform: uppercase;
      font-size: 24px;
      padding-left: 16px;
    }
  }

  & .link-holder {
    display: flex;
    & a {
      align-self: center;
      text-decoration: none;    
      text-transform: capitalize;
      color:inherit;
      padding-left: 16px;
    }
  }
}

.Footer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 12px;
  background-color: black;
  box-shadow: 5px 5px 15px 5px rgba(0,0,0, 0.05);
  font-size: 48px;
  color: white;
  & .img-holder {
    display: flex;
    justify-content: center;
    padding:32px;
    & img {
      width: 240px;
      height: 240px;
      border-radius: 120px;
    }
  }

  & .hashtags {
    display: flex;
    flex-direction: column;
    padding-bottom: 32px;
    & * {
      align-self: center;
    }
  }

  & .acknowledgements {
    display: flex;
    flex-direction: column;
    font-size: 12px;
    color: #aaa;
    & * {
      align-self: center;
    }
  }

  & .link-holder {
    display: flex;
    justify-content: center;
    & a {
      align-self: center;
      text-decoration: none;    
      text-transform: capitalize;
      color:inherit;
      padding-left: 16px;
    }
  }
}

.Content {
  display: flex;
  flex-direction: column;
  & .MuiButtonBase-root {
    background-color: var(--color-red);
    color: white;
    font-weight: 800;
    font-size: 22px;
  }
  & > :not(.hero-image) {
    padding-left: 32px;
    padding-right: 32px;
    max-width: 1000px;
    align-self: center;
  }

  & .summary {
    width: calc(100% - 64px);
    & img {
      width: 300px;
    }
  }

  & p {
    line-height: 200%;
    padding-bottom: 22px;
  }

  & .hero-image {
    min-height: 220px;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    color: white;
    & .hero-image-inner {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      & h1 {
        margin-top:64px;
        text-shadow: 2px 2px 10px black;
      }
      & * {
        align-self: center;
      }
    }
  }

  & li {
    padding-bottom: 24px;
  }
}

.GetInvolved {
  & .summary {
    & h1 {
      margin-top: 22px;
    }
    text-align: center;
    padding-bottom: 26px;
    & p {
      padding-bottom: 4px;
      font-size: 18px;
    }
  }

  & .hero-image {
    min-height: 160px;
  }
  & .hero-image-inner {
    justify-content: center;
  }
}

.Resources {
  & .hero-image {
    min-height: 160px;
  }
  & a {
    text-decoration: none;    
    color:#0000EE;
  }
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.bold {
  font-weight: bold;
}

@media (max-width: 930px) {
  .sm-col {
    flex-direction: column;
  }
}

.AboutUs {
  & li {
    padding-bottom: unset;
  }
  & a {
    text-decoration: none;    
    color:#0000EE;
  }

  & .hero-image-inner p {
    text-shadow: 2px 2px 10px black;
    font-size: 18px;
    text-align: center;
  }

  & .org-holder {
    display: flex;
    flex-direction: column;
    width: 164px;
    text-align: center;
    padding-bottom: 32px;
    & img {
      align-self: center;
      width: 128px;
      height: 128px;
      border-radius: 64px;
    }
  }
}

.ResourceInfo {
  flex-basis: 50%;
  flex-grow: 1;
  flex-shrink: 1;
  padding-bottom: 24px;

  & a {
    text-decoration: none;    
    color: #0000EE;
  }

  & .resource-info-inner {
    display: flex;
  }

  & .icon-holder {
    font-size: 36px;
    padding-right: 8px;

    & svg {
      color:white;
      box-shadow: 5px 5px 15px 5px rgba(0,0,0, 0.05);
      background-color: var(--color-red);
      padding: 8px;
      border-radius: 50%;
    }
  }

  & .text-holder {
    display: flex;
    flex-direction: column;
    & span {
      flex-grow: 1;
    }

    & .title {
      font-weight: bold;
      padding-bottom: 8px;
      font-size: 18px;
    }
  }
}